import React, {useEffect, useState} from 'react';
import Header from "./components/header";
import "./global.sass"
import {ChakraProvider, Text} from '@chakra-ui/react'
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import "./app.sass"
import { Input,Textarea } from '@chakra-ui/react'
import { Select } from '@chakra-ui/react'
import { Button, ButtonGroup } from '@chakra-ui/react'
import axios from "axios";
import {CheckCircleIcon} from '@chakra-ui/icons'
import { Icon } from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
function App() {

  const [storeName,setStoreName] = useState<string>("")

  const [time,setTime] = useState<{
    id:string
    index:number
    text:string
  }[]>([])

  const [isError,setIsError] = React.useState(false)
  const [isSuccess,setIsSuccess] = React.useState(false)

  const [isGift,setIsGift] = React.useState(false)

  const [fromPostNumber,setFromPostNumber] = React.useState("")
  const [fromAddress,setFromAddress] = React.useState("")
  const [fromName,setFromName] = React.useState("")
  const [fromPhoneNumber,setFromPhoneNumber] = React.useState("")

  const [toPostNumber,setToPostNumber] = React.useState("")
  const [toAddress,setToAddress] = React.useState("")
  const [toName,setToName] = React.useState("")
  const [toPhoneNumber,settoPhoneNumber] = React.useState("")

  const [memo,setMemo] = React.useState("")
  const [deliveryDate,setDeliveryDate] = React.useState("")
  const [deliveryTime,setDeliveryTime] = React.useState("")

  const search = async (type:string) => {
    if (type === "from") {
        const resp = await axios.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${fromPostNumber}`)
        setFromAddress(resp.data.results[0].address1 + resp.data.results[0].address2 + resp.data.results[0].address3)
    } else {
        const resp = await axios.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${toPostNumber}`)
        setToAddress(resp.data.results[0].address1 + resp.data.results[0].address2 + resp.data.results[0].address3)
    }
  }

  useEffect(()=>{
    const id = window.location.pathname.replace(/\//g,"");
    (async()=> {
      try {
        const resp =  await axios.get(process.env.REACT_APP_ENDPOINT + "/client/transaction/?id=" + id)
        setStoreName(resp.data.store_name)
        const sorted = resp.data.datetime.sort((a:any,b:any)=> {
            if (a.index < b.index) {
                return -1
            } else {
                return 1
            }
        })
        setTime(sorted)
      } catch (error) {
        setIsError(true)
      }

    })()
  },[])

  const toast = useToast()

  const submit = async () => {
    if (toPostNumber === "" || toAddress === "" || toName === "" || toPhoneNumber === "") {
      toast({
        position:"top-right",
        title: "必須項目が入力されていません",
        status: "error",
        isClosable: true,
      })
      return
    }
    if (fromPostNumber !== "" || fromAddress !== "" || fromName !== "" || fromPhoneNumber !== "") {
      if (fromPostNumber === "" || fromAddress === "" || fromName === "" || fromPhoneNumber === "") {
        toast({
          position:"top-right",
          title: "依頼主情報を入力する場合は全項目に入力してください",
          status: "error",
          isClosable: true,
        })
        return
      }
    }
    const id = window.location.pathname.replace(/\//g,"");
    const req:{
      "id": string,
      "is_gift": boolean,
      "from_post_number": string,
      "from_address": string,
      "from_name": string,
      "from_phone_number": string,
      "to_post_number":  string,
      "to_address": string,
      "to_name": string,
      "to_phone_number": string,
      "to_date_time": string,
      "memo": string
    } = {
      "id": id,
      "is_gift": isGift,
      "from_post_number": fromPostNumber,
        "from_address": fromAddress,
      "from_name": fromName,
      "from_phone_number": fromPhoneNumber,
      "to_post_number":  toPostNumber,
      "to_address": toAddress,
      "to_name": toName,
      "to_phone_number": toPhoneNumber,
      "to_date_time": "",
      "memo": memo
    }
    if (deliveryDate !== "" && deliveryTime !== "") {
      req.to_date_time = deliveryDate + ":" + deliveryTime
    } else if (deliveryDate !== "") {
        req.to_date_time = deliveryDate
    } else if (deliveryTime !== "") {
        req.to_date_time = "指定無し:"+deliveryTime
    } else {
        req.to_date_time = "指定無し"
    }
    try {
      await axios.post(process.env.REACT_APP_ENDPOINT + "/client/transaction/",req)
      setIsSuccess(true)
    } catch (error) {
      toast({
        position:"top-right",
        title: "送信に失敗しました",
        status: "error",
      })
    }
  }

  return (
      <ChakraProvider>
        {
          isError && <div className={"errorRoot"}>
          <div>
          <h1>404</h1>
          <p>取引が見つかりませんでした。</p>
            <p>お手数ですが、スタッフにお知らせください。</p>
          </div>
            </div>
        }
          {
            isSuccess && <div className={"successRoot"}>
            <div>
                <Icon as={CheckCircleIcon} boxSize={12} color={"teal.500"}></Icon>
              <p>送信しました</p>
              <p>送信内容の変更をご希望の場合は、スタッフにお申し付けください。</p>
            </div>
              </div>
          }
        {
          !isError && !isSuccess &&     <div className="App" style={{padding:"0",margin:"0"}}>
              <Header storeName={storeName}></Header>
              <div className={"body"}>
                <div className={"section"}><Checkbox colorScheme={"teal"} onChange={()=>{setIsGift(!isGift)}} size={"lg"}>ギフトとして配送</Checkbox></div>
                <div className={"section"}>
                  <p>依頼主情報（任意）</p>
                  <p>伝票の依頼主欄についてご希望がありましたらご記入ください</p>
                  <div className={"formWrapper"}>
                    <Input placeholder='依頼主郵便番号（ハイフン無し）' size={"lg"} onChange={(e)=>{setFromPostNumber(e.target.value)}}/>

                  </div>
                  <div className={"formWrapper"}>
                    <Button colorScheme='teal' size={"sm"} onClick={()=>{search("from")}}>郵便番号から住所を検索</Button>
                  </div>
                  <div className={"formWrapper"}>
                    <Textarea placeholder='依頼主住所' value={fromAddress} size={"lg"} onChange={(e)=>{setFromAddress(e.target.value)}}/>
                  </div>
                  <div className={"formWrapper"}>
                    <Input placeholder='依頼主氏名' size={"lg"} onChange={(e)=>{setFromName(e.target.value)}}/>
                  </div>
                  <div className={"formWrapper"}>
                    <Input placeholder='依頼主電話番号' size={"lg"} onChange={(e)=>{setFromPhoneNumber(e.target.value)}}/>
                  </div>
                  <p>配送先情報（必須）</p>
                  <div className={"formWrapper"}>
                    <Input placeholder='配達先郵便番号（ハイフン無し）' size={"lg"} onChange={(e)=>{setToPostNumber(e.target.value)}}/>
                  </div>
                  <div className={"formWrapper"}>
                    <Button colorScheme='teal' size={"sm"} onClick={()=>{search("to")}}>郵便番号から住所を検索</Button>
                  </div>
                  <div className={"formWrapper"}>
                    <Textarea placeholder='配達先住所' value={toAddress} size={"lg"} onChange={(e)=>{setToAddress(e.target.value)}}/>
                  </div>
                  <div className={"formWrapper"}>
                    <Input placeholder='配達先宛名' size={"lg"} onChange={(e)=>{setToName(e.target.value)}}/>
                  </div>
                  <div className={"formWrapper"}>
                    <Input placeholder='配達先電話番号' size={"lg"} onChange={(e)=>{settoPhoneNumber(e.target.value)}}/>
                  </div>
                  <div className={"formWrapper"}>
                    <p>配達希望日（任意）</p>
                    <Input type={"date"} size={"lg"} onChange={(e)=>{setDeliveryDate(e.target.value)}}/>
                  </div>
                  <div className={"formWrapper"}>
                    <Select placeholder='配達希望時間（任意）' onChange={(e)=>{setDeliveryTime(e.target.value)}}>
                      {
                        time.map((t:any)=> {
                            return <option value={t.id}>{t.text}
                            </option>
                      })
                      }
                    </Select>
                  </div>
                  <div className={"formWrapper"}>
                    <Textarea placeholder='その他ご希望（任意）' size={"lg"} onChange={(e)=>{setMemo(e.target.value)}}/>
                  </div>
                  <Button colorScheme='teal' width={"full"} size={"lg"} onClick={()=>{submit()}}>
                    送信
                  </Button>
                </div>
              </div>
            </div>
        }
      </ChakraProvider>
  );
}

export default App;
